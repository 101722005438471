import React from "react";
import Seo from "../seo";

const MockupContainer = ({children, title}) => {
    title = title ?? "Create"
    return (
        <>
        <Seo title={`${title} | Mockup Clips`} />
        <div className="mockupEditor flex flex-col bg-light-gradient min-h-full">
            {children}
        </div>
        </>
    )
}
export default MockupContainer;