import React from "react";
import { useLocation } from '@reach/router'
import Logo from "../../images/logo.svg";
import { Link } from "gatsby"
import { BiCheck } from "react-icons/bi";

const MockupHeader = ({hideSteps}) => {
    const { pathname } = useLocation();

    // set active steps
    var activeStep = 1;
    if (pathname === '/template') { activeStep = 1; }
    else if (pathname === '/choose-media') { activeStep = 2; }
    else if (pathname === '/editor') { activeStep = 3; }

    return (
        <header className="bg-black py-4">
            <div className="max-w-screen-2xl mx-auto px-10 flex flex-wrap items-center justify-between">
                <div className="flex font-medium items-center justify-center w-full lg:w-auto mb-3 lg:mb-0">
                    <Link to="/template"><img src={Logo} alt={"logo"} className="h-6" /></Link>
                </div>

                {!hideSteps && (
                    <div className="stepsContainer flex justify-center items-center flex-1 px-10 text-sm">
                        <div className="step flex items-center">
                            <div className={`flex items-center justify-center h-7 w-7 rounded-full text-xs leading-none border-2 ${activeStep === 1 ? 'text-gray-200 bg-indigo-500 bg-opacity-15 border-indigo-600' : 'text-gray-600 bg-gray-500 bg-opacity-10 border-gray-700 border-opacity-75'} `}>
                                {activeStep > 1 ? <BiCheck /> : '1'}
                            </div>
                            <Link to="/template" className={`ml-2 hidden md:block transition duration-100 ${activeStep === 1 ? 'text-gray-200' : 'cursor-pointer text-gray-600 hover:text-gray-200'} `}>Select Template</Link>
                        </div>
                        <div className="step flex items-center pl-2">
                            <div className={`flex items-center justify-center h-7 w-7 rounded-full text-xs leading-none border-2 ${activeStep === 2 ? 'text-gray-200 bg-indigo-500 bg-opacity-15 border-indigo-600' : 'text-gray-600 bg-gray-500 bg-opacity-10 border-gray-700 border-opacity-75'} `}>
                                {activeStep > 2 ? <BiCheck /> : '2'}
                            </div>
                            <Link to="/choose-media" className={`ml-2 hidden md:block transition duration-100 ${activeStep === 2 ? 'text-gray-200' : 'cursor-pointer text-gray-600 hover:text-gray-200'} `}>Choose Media</Link>
                        </div>
                        <div className="step flex items-center pl-2">
                            <div className={`flex items-center justify-center h-7 w-7 rounded-full text-xs leading-none border-2 ${activeStep === 3 ? 'text-gray-200 bg-indigo-500 bg-opacity-15 border-indigo-600' : 'text-gray-600 bg-gray-500 bg-opacity-10 border-gray-700 border-opacity-75'} `}>
                                {activeStep > 3 ? <BiCheck /> : '3'}
                            </div>
                            <Link to="/editor" className={`ml-2 hidden md:block transition duration-100 ${activeStep === 3 ? 'text-gray-200' : 'cursor-pointer text-gray-600 hover:text-gray-200'} `}>Edit Mockup</Link>
                        </div>
                    </div>
                )}

            <div className="hidden lg:flex font-medium items-center"><img src={Logo} alt={"logo"} className="h-6 invisible" /></div>
            </div>
        </header>
    )
}
export default MockupHeader;